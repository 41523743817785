.color-blue {
  color: rgb(0, 179, 230);
}

.bg-color-blue {
  background-color: rgb(0, 179, 230);
}

.color-darkblue {
  color: rgb(0, 109, 182);
}

.header {
  border-bottom: 1px solid #eaeaea;
}

.card {
  border-left: 1px solid #d2d5d7 !important;
  border-right: 1px solid #d2d5d7 !important;
  border-bottom: 1px solid #d2d5d7 !important;
}
.card .card-content {
  min-height: 155px;
}